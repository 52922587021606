<template>
    <div class="c-search-page">
      <div class="c-search-page__searchbar">
        <input type="text" v-model="searchText" placeholder="Tippe um zu suchen.." @input="search">
      </div>

      <div v-if="isLoadingResults" class="c-search-page__loading">
        <i class="xp xp-spinner xp--large is-spinning"></i>
      </div>

      <MenuEntry v-if="!isLoadingResults && resultAsMenuEntry" class="c-search-page__results" :entryData="resultAsMenuEntry" />
    </div>
</template>

<script>
import { pageMixin } from '@xpedeo/core'
const LS_SEARCH_KEY = 'search-key'
const LS_SEARCH_Y_POS = 'search-y-pos'

export default {
  name: 'XpSearchPage',
  mixins: [pageMixin],
  data () {
    return {
      searchText: null,
      searchYPos: 0,
      isLoadingResults: false,
      resultAsMenuEntry: null
    }
  },
  created () {
    const search = localStorage.getItem(LS_SEARCH_KEY)
    if (search) {
      this.searchText = JSON.parse(search)
    }

    const searchYPos = localStorage.getItem(LS_SEARCH_Y_POS)
    if (searchYPos) {
      this.searchYPos = JSON.parse(searchYPos)
    }
    this.search()
  },
  methods: {
    search () {
      localStorage.setItem(LS_SEARCH_KEY, JSON.stringify(this.searchText))
      this.isLoadingResults = true
      const url = this.$xp.settings.baseUrl + 'xpedeo/api/v1/pages/search' + (this.searchText ? '/' + this.searchText : '')
      this.$xp.http.__axiosInstance.get(url).then((response) => {
        console.log(response.data)
        this.resultAsMenuEntry = {
          Class: 'Menu',
          Type: 'list',
          Json: {
            ShowThumbnails: false
          },
          MenuItems: response.data
        }
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.isLoadingResults = false
        setTimeout(() => {
          document.querySelector('#page-content').scrollTop = this.searchYPos
          this.handleClickEvent()
        }, 100)
      })
    },
    handleClickEvent () {
      const clickHandler = (event) => {
        const pageYScroll = document.querySelector('#page-content').scrollTop
        event.currentTarget.removeEventListener('click', clickHandler)
        localStorage.setItem(LS_SEARCH_Y_POS, JSON.stringify(pageYScroll))
      }
      document.querySelectorAll('.c-search-page__results .c-menu-entry__item').forEach((element) => {
        element.addEventListener('click', clickHandler)
      })
    }
  }
}
</script>
